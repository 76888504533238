<template>
  <div id="bg">
    <div class="search">
      <div class="search_box">
        账号:<el-input class="width_200" v-model="search.account" placeholder="请输入账号"></el-input>
        <el-button type="primary" size="medium" @click="showDialog()">添加</el-button>
        <el-button type="primary" size="medium" @click="getList()">查询</el-button>
      </div>
    </div>
    <el-table :data="tableData" :header-cell-style="{background:'#F7F8FA'}">
      <el-table-column prop="uid" label="UID" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="account" label="账号" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="type" label="禁言类型" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
            <span v-if="scope.row.type == 0">禁言</span>
            <span v-if="scope.row.type == 1">禁止上传</span>
<!--            <span v-if="scope.row.type == 2">拉黑</span>-->
        </template>
      </el-table-column>
      <el-table-column prop="add_time" label="添加时间" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column prop="slient_day" label="时长" align="center" :show-overflow-tooltip="true">
        <template #default="scope">
          <span>{{getSilentTitle(scope.row.slient_day)}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="expire_time" label="结束时间" align="center" :show-overflow-tooltip="true">
      </el-table-column>
      <el-table-column prop="remark" label="备注" align="center" :show-overflow-tooltip="true"></el-table-column>
      <el-table-column label="操作" align="center">
        <template #default="scope">
          <span class="operation el-icon-edit" @click="showDialog(scope.row)" >编辑</span>
          <span class="operation el-icon-delete" @click="del(scope.row)" >删除</span>
        </template>
      </el-table-column>
    </el-table>
    <page :pager="pager" v-if="pager.page > 1" @query="getList()" />
    <el-dialog :title="silentTitle" v-model="silentVisible" width="600px" height="500px" top="5vh" :close-on-click-modal="false" >
      <el-row>
        <el-col :span="4" align="right">账号</el-col>
        <el-col :span="20">
          <el-input v-model="silentDialog.username" placeholder="请输入禁言账号"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">禁言类型</el-col>
        <el-col :span="20">
          <el-select v-model="silentDialog.type" :disabled="silentDialog.isDisable">
            <el-option label="禁言" :value=0 ></el-option>
            <el-option label="禁止上传" :value=1 ></el-option>
<!--            <el-option label="拉黑" :value=2 ></el-option>-->
          </el-select>
        </el-col>
      </el-row>
      <el-row v-if="silentDialog.type != 2">
        <el-col :span="4" align="right">禁言时间</el-col>
        <el-col :span="20">
          <el-select v-model="silentDialog.silent_day_selection" @change="silentDialog.silent_day = silentDialog.silent_day_selection">
            <el-option v-for="item of silentTimeOptions" :key="item" :value="item.value" :label="item.label"></el-option>
          </el-select>
          <el-input class="width_150" v-model="silentDialog.silent_day" placeholder="禁言时间"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="4" align="right">备注</el-col>
        <el-col :span="20">
          <el-input v-model="silentDialog.reason" placeholder="请输入禁言备注"></el-input>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="6" align="right"></el-col>
        <el-col :span="18">
          <el-button @click="silentVisible=false">关闭</el-button>
          <el-button @click="saveSilent()">保存</el-button>
        </el-col>
      </el-row>
      <div style="height: 20px;"></div>
    </el-dialog>

  </div>
</template>

<script>
import httpClient from "@/config/httpClient";
import page from "@/components/page";
export default {
  name: "community_keyword",
  components: {
    page,
  },
  data(){
    return {
      search:{
        type:-1,
        account:"",
      },
      pager: { total: 0, page: 1, rows: 50 },
      tableData:[
      ],
      silentVisible:false,
      silentTitle:"新增禁言",
      silentDialog:{
        isDisable:false,
        silent_day_selection:60,
        silent_day:60,
        reason:"",
        username:"",
        type:0,
      },
      silentTimeOptions:[
        {value:60,label:"1分钟"},
        {value:60*5,label:"5分钟"},
        {value:60*15,label:"15分钟"},
        {value:60*30,label:"半小时"},
        {value:3600,label:"1小时"},
        {value:86400,label:"1天"},
        {value:86400*30,label:"30天"},
        {value:86400*365,label:"365天"},
        {value:0,label:"永久"},
      ],
    }
  },
  mounted() {
    this.getList();
  },
  methods:{
    getSilentTitle(silent_day){
      for(const row of this.silentTimeOptions){
        if (row.value == silent_day){
          return row.label
        }
      }
      return silent_day
    },
    del(row){
      this.$confirm("删除用户禁言" + row.account + "吗？", "提示", { confirmButtonText: "确定", cancelButtonText: "取消", type: "warning" }).then(()=>{
        this.$common.showLoading()
        httpClient("DelSilentUid").post({
          user_ids:row.uid,
          type:row.type,
        }).then((res)=>{
          this.$common.hideLoading()
          if (res.code > 0){
            this.$message({message:res.msg,type:"error"})
          }else{
            this.getList()
          }
        },(err)=>{
          this.$common.hideLoading()
          console.log(err)
        })
      })
    },
    getList(){
      this.$common.showLoading()
      httpClient("GetSilentList").post({
        row:this.pager.rows,
        page:this.pager.page,
        account:this.search.account,
      }).then((res)=>{
        this.$common.hideLoading()
        if (res.code == 0){
          this.tableData = res.data.list
          if (this.pager.page == 1){
            this.pager.total = res.data.total
          }
        }else{
          this.$message({message:res.msg,type:"error"})
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    },
    showDialog(row){
      this.silentVisible = true
      this.silentDialog.username = ""
      this.silentDialog.type = 0
      this.silentDialog.reason = ""
      this.silentDialog.silent_day_selection = 60
      this.silentDialog.isDisable = false
      if (row){
        this.silentDialog.isDisable = true
        this.silentDialog.username = row.account
        this.silentDialog.silent_day_selection = row.slient_day
        this.silentDialog.type = row.type
        this.silentDialog.reason = row.remark
      }
      this.silentDialog.silent_day = this.silentDialog.silent_day_selection
    },
    saveSilent(){
      console.log(this.silentDialog)
      if (this.silentDialog.username == ''){
        this.$message({message:"请选择要禁言的用户!",type:"error"})
        return
      }
      this.$common.showLoading()
      httpClient("AddSilentByUsername").post({
        type:this.silentDialog.type,
        username:this.silentDialog.username,
        silent_time:this.silentDialog.silent_day,
        remark:this.silentDialog.reason,
      }).then((res)=>{
        this.$common.hideLoading()
        if (res.code > 0){
          this.$message({message:res.msg,type:"error"})
        }else{
          this.silentVisible = false
          this.getList()
        }
      },(err)=>{
        this.$common.hideLoading()
        console.log(err)
      })
    }
  }
}
</script>

<style scoped>
.el-row{
  margin-bottom: 5px;
}
.el-row .el-col{
  padding-right: 5px;
}
</style>